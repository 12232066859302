<template>
  <div class="w-full pl-500px">
    <div class="max-w-500 w-full mx-auto py-20">
      <h2 class="text-4xl font-medium text-black-900 mb-8">Contact Us</h2>
      <t-alert variant="success" :show="contactUsSuccess" class="my-4">
        Your message has been submitted succesfully, we'll contact you shortly
      </t-alert>
      <t-alert variant="danger" :show="contactUsError.length > 0" class="my-4">
        {{ contactUsError }}
      </t-alert>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="space-y-6 mb-11"
      >
        <utils-input
          validationRules="required"
          inputName="Full Name"
          inputType="text"
          :isSubmitted="isSubmitted"
          v-model="data.full_name"
        />

        <utils-input
          validationRules="required|email"
          inputName="Email"
          inputType="text"
          :isSubmitted="isSubmitted"
          v-model="data.email"
        />

        <utils-input
          validationRules="required"
          inputName="Phone Number (+6512345678)"
          inputType="text"
          :isSubmitted="isSubmitted"
          v-model="data.phone_number"
        />

        <utils-textbox
          validationRules="required"
          inputName="Message"
          :isSubmitted="isSubmitted"
          :rowCount="5"
          v-model="data.message"
        />

        <vue-recaptcha
          ref="recaptcha"
          :sitekey="GOOGLE_RECAPTCHA_SITE_KEY"
          @verify="handleVerify"
        ></vue-recaptcha>

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm"> Submit</span>
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";
import UtilsTextbox from "@/components/utils/UtilsTextbox.vue";
import { REFRESH_CONTACT_US, CREATE_CONTACT_US } from "@/store//actions.type";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "@/common/config";

const initialData = {
  full_name: "",
  email: "",
  phone_number: "",
  message: "",
  g_recaptcha_response: "",
};

export default {
  name: "AuthContactUsForm",
  components: {
    "utils-input": UtilsInput,
    "utils-textbox": UtilsTextbox,
    "base-button": BaseButton,
    ValidationObserver,
    VueRecaptcha,
  },
  created() {
    this.GOOGLE_RECAPTCHA_SITE_KEY = GOOGLE_RECAPTCHA_SITE_KEY;
  },
  mounted() {
    this.$store.dispatch(REFRESH_CONTACT_US);
  },
  data() {
    return {
      isSubmitted: false,
      data: { ...initialData },
    };
  },
  computed: {
    ...mapGetters(["contactUsSuccess", "contactUsError"]),
  },
  methods: {
    handleVerify(event) {
      this.data.g_recaptcha_response = event;
    },
    handleSubmit() {
      this.$store.dispatch(REFRESH_CONTACT_US);

      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        this.$refs.recaptcha.reset();

        if (!valid) {
          return;
        }

        await this.$store.dispatch(CREATE_CONTACT_US, this.data);

        if (this.contactUsSuccess) {
          this.data = { ...initialData };
        }
      });
    },
  },
};
</script>
