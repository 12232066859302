<template>
  <validation-provider
    :rules="validationRules"
    v-slot="{ errors }"
    mode="passive"
    tag="div"
    class="relative"
  >
    <div class="flex justify-between items-center mb-2">
      <label :for="inputName" class="block text-grey-700 text-sm">{{
        inputName
      }}</label>
      <slot />
    </div>
    <textarea
      :id="inputName"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :rows="rowCount"
      class="
        text-base text-black-900
        rounded
        border border-grey-500
        px-2
        py-2
        w-full
      "
    ></textarea>
    <p
      v-if="isSubmitted && errors && errors.length"
      class="absolute left-0 top-full mt-1 text-xs text-red-600"
    >
      {{ errors[0] }}
    </p>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "UtilsTextbox",
  components: {
    ValidationProvider,
  },
  props: {
    validationRules: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    rowCount: {
      type: Number,
      required: false,
      default: 3,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
